import React, { useState } from "react";
import "./FAQ.css";
import arrow from "../assets/imgs/arrow.svg";

const FAQ = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="FAQ-container" id="FAQ">
      <h3>FAQ's</h3>
      <p>Providing answers to your questions</p>

      {faqData.map((item, index) => (
        <div
          key={index}
          className={`FAQ-item ${activeIndex === index ? "open" : ""}`}
        >
          <div className="FAQ-question" onClick={() => toggleFAQ(index)}>
            <p>{item.question}</p>
            <div className="arrow-circle-wrapper clickable">
              <img src={arrow} alt="arrow-image" />
            </div>
          </div>
          <div
            className="FAQ-answer"
            style={{
              maxHeight:
                activeIndex === index
                  ? `${
                      document.querySelectorAll(".FAQ-answer")[index]
                        .scrollHeight
                    }px`
                  : "0px",
            }}
          >
            <p>{item.answer}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

const faqData = [
  {
    question: "How long does it take to complete a website?",
    answer:
      "The timeline for website completion varies based on the project's size and complexity. Our goal is to deliver a high-quality website, and this may require some time. However, you can expect to receive the initial web design within the first week.",
  },
  {
    question: "Can I request changes after website is finished?",
    answer:
      "Yes, we offer unlimited revisions after the website is completed. We encourage constructive feedback to ensure the final product meets your expectations, while also focusing our efforts on meaningful improvements.",
  },
  {
    question: "What do you build websites with?",
    answer:
      "Our websites are built using CSS and JavaScript, ensuring a responsive, dynamic, and modern user experience.",
  },
  {
    question: "Do you offer refunds?",
    answer:
      "Refunds are available only if we are unable to complete the project. We are committed to delivering on our promises and ensuring your satisfaction with the final product.",
  },
];

export default FAQ;
