import React, { useEffect, useState } from "react";
import GLOBE from "vanta/dist/vanta.globe.min"; // Import the GLOBE effect
import * as THREE from "three"; // Import THREE.js
import "./Header.css";
import Menu from "./Menu.js";
import Marquee from "react-fast-marquee";

const Header = () => {
  const [vantaEffect, setVantaEffect] = useState(null);
  const headerRef = React.createRef();

  useEffect(() => {
    if (!vantaEffect) {
      setVantaEffect(
        GLOBE({
          el: headerRef.current,
          mouseControls: true,
          touchControls: true,
          gyroControls: true,
          THREE, 
          color: 0x51fff8, 
          backgroundColor: 0x000000, 
          backgroundAlpha: 0.5, 
          size: 1.0, 
          scale: 1.0, 
          points: 10.0, 
          maxDistance: 20.0, 
        })
      );
    }
    return () => {
      if (vantaEffect) vantaEffect.destroy(); // Cleanup the effect on unmount
    };
  }, []);

  return (
    <>
      <div className="header-container-opacity" id="home">
        <div className="header-container" ref={headerRef}>
          <Menu />
          <div className="weblience-logo">WEBLIENCE</div>
          <h1>
            Futuristic & Strategic Websites That <span>Convert</span>.
          </h1>
          <p>
            We empower startups and small businesses to grow by integrating
            strategic planning in custom web development.
          </p>
          <a
            href="https://calendly.com/weblience"
            target="_blank"
            rel="noopener noreferrer"
          >
            <button className="btn-primary">Book a meeting</button>
          </a>
        </div>
        <div className="weLead-container">
          <hr />
          <p>WE LEAD THE WAY TO YOUR SUCCESS</p>
          <hr />
        </div>
      </div>
      <div className="marquee-container" id="header">
        <Marquee id="marquee-header">
          <li>WEB DESIGN</li>
          <li>WEB DEVELOPMENT</li>
          <li>UX & UI</li>
          <li>BUSINESS CONSULTING</li>
          <li>STRATEGY</li>
        </Marquee>
      </div>
    </>
  );
};

export default Header;
