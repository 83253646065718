import React, { useEffect } from "react";
import "./Footer.css";
import mail from "../assets/imgs/mail.svg";
import phone from "../assets/imgs/phone.svg";
import pinterest from "../assets/imgs/pinterest.svg";
import X from "../assets/imgs/X.svg";
import inL from "../assets/imgs/in.svg";

const Footer = () => {
  useEffect(() => {
    const contactLinks = document.querySelectorAll(".contact-link-footer");
    const contactModal = document.querySelector(".contact-modal");
    const closeButton = document.querySelector(".close");
    const app = document.querySelector('.App');

    contactLinks.forEach((contactLink) => {
      contactLink.addEventListener("click", (e) => {
        e.preventDefault(); // Prevent the default anchor behavior
        contactModal.style.display = "flex";
        app.style.opacity = 0.2;
      });
    });

    closeButton.addEventListener("click", () => {
      contactModal.style.display = "none";
      app.style.opacity = 1;
    });

    // Cleanup event listeners on component unmount
    return () => {
      contactLinks.forEach((contactLink) => {
        contactLink.removeEventListener("click", () => {});
      });
      closeButton.removeEventListener("click", () => {});
      window.removeEventListener("click", () => {});
    };
  }, []);
  return (
    <div className="footer-container">
      <div className="white-rectangle-footer"></div>
      <div className="start-building-today">
        <div className="circle-gradient"></div>
        <h3>Start building your new website today!</h3>
        <p>
          Jump on a call with us and let us help you with your expectations.
        </p>
        <button className="btn-primary contact-link-footer">Contact us</button>
      </div>
      <div className="footer-info">
        <div className="footer-top">
          <div className="footer-top-left">
            <h4>WEBLIENCE</h4>
            <p>
              Weblience is a web development club full of motivated young people
              that want to help startups and businesses grow online.
            </p>
          </div>
          <div className="footer-top-right">
            <div className="footer-top-right-item">
              <img src={mail} alt="mail icon" />
              <p>{process.env.REACT_APP_EMAIL}</p>
            </div>
            <div className="footer-top-right-item">
              <img src={phone} alt="phone icon" />
              <p>+ 421 949 091 783</p>
            </div>
            <div className="footer-top-right-item">
              <img src={pinterest} alt="pinterest icon" />
              <img src={X} alt="X icon" />
              <img src={inL} alt="linkedin icon" />
            </div>
          </div>
        </div>
        <hr />
        <div className="footer-bottom">
          <div className="footer-bottom-left">
            <a href="#home" className="clickable">
              <p>Home</p>
            </a>
            <a href="#processes" className="clickable">
              <p>Process</p>
            </a>
            <a href="#pricing" className="clickable">
              <p>Pricing</p>
            </a>
            <a className="contact-link-footer clickable">
              <p> Contact</p>
            </a>
            <a href="#FAQ" className="clickable">
              <p>FAQ</p>
            </a>
          </div>
          <div className="footer-bottom-right">
            <p>2024</p>
            <p>WEBLIENCE</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
