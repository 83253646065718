import React, { useState, useEffect, useRef } from 'react'
import close from "../assets/imgs/close.svg";
import './ContactModal.css'
import { faCheck, faTimes, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import emailjs from '@emailjs/browser';

const NAME_REGEX = /^[a-zA-Z]{2,}( [a-zA-Z]{2,}){0,}$/;
const EMAIL_REGEX = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
const TEXT_REGEX = /^[\w\W\s\S]{2,1000}$/;

function ContactModal() {
    const form = useRef();

    const [name, setName] = useState('');
    const [validName, setValidName] = useState(false);
    const [nameFocus, setNameFocus] = useState(false);

    const [email, setEmail] = useState('');
    const [validEmail, setValidEmail] = useState(false);
    const [emailFocus, setEmailFocus] = useState(false);

    const [text, setText] = useState('');
    const [validText, setValidText] = useState(false);
    const [textFocus, setTextFocus] = useState(false);

    const [errMsg, setErrMsg] = useState('');
    const [success, setSuccess] = useState(false);

    useEffect(() => {
        const result = NAME_REGEX.test(name);
        setValidName(result)
    }, [name]);

    useEffect(() => {
        const result = EMAIL_REGEX.test(email);
        setValidEmail(result);
    }, [email]);

    useEffect(() => {
        const result = TEXT_REGEX.test(text);
        setValidText(result);
    }, [text]);

    useEffect(() => {
        setErrMsg('');
    }, [name, email, text]);

    const sendEmail = (e) => {
        e.preventDefault();

        const ch1 = NAME_REGEX.test(name);
        const ch2 = EMAIL_REGEX.test(email);
        const ch3 = TEXT_REGEX.test(text);
        console.log(ch1 + " " + ch2 + " " + ch3)
        if (!ch1 || !ch2 || !ch3) {
            setErrMsg('Invalid entry');
        } else {
            emailjs.sendForm(process.env.REACT_APP_EMAILJS_SERVICE_ID,
                process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
                form.current, {
                publicKey: process.env.REACT_APP_EMAILJS_PUBLIC_KEY,
            }).then(() => {
                setSuccess(true)
                form.current.reset();
                setEmail('');
                setName('');
                setText('');
            },
                (error) => {
                    setErrMsg('No server response');
                },
            );
        }
    };

    return (
        <div className='contact-modal'>
            {success ? (
                <div className='success-container'>
                    <header>
                        <h3>Thank you for contacting us!</h3>
                        <img className="close clickable" src={close} alt="close icon" onClick={() => {
                            const contactModal = document.querySelector(".contact-modal");
                            const app = document.querySelector('.App');
                            contactModal.style.display = "none";
                            app.style.opacity = 1;
                            setSuccess(false);
                        }} />
                    </header>
                    <p>
                        Our team member will be contacting you in the next few days to discuss your needs.
                    </p>
                </div>
            ) : (
                <form ref={form} onSubmit={sendEmail}>
                    <header>
                        <h3>Contact us</h3>
                        <img className="close clickable" src={close} alt="close icon" onClick={() => {
                            const contactModal = document.querySelector(".contact-modal");
                            const app = document.querySelector('.App');
                            contactModal.style.display = "none";
                            app.style.opacity = 1;
                            form.current.reset();
                            setEmail('');
                            setName('');
                            setText('');
                        }} />
                    </header>
                    <p>
                        Contact us through email or book a meeting through calendly so we can
                        discuss your needs.
                    </p>

                    {/* Name */}

                    <label htmlFor='name'>Your name
                        <span className={validName ? 'valid' : 'hide'}>
                            <FontAwesomeIcon icon={faCheck} />
                        </span>
                        <span className={validName || !name ? 'hide' : 'invalid'}>
                            <FontAwesomeIcon icon={faTimes} />
                        </span>
                    </label>
                    <input
                        type='text'
                        id='name'
                        name='name'
                        autoComplete='off'
                        onChange={(e) => { setName(e.target.value) }}
                        required
                        aria-invalid={validName ? 'false' : 'true'}
                        aria-describedby='form-note'
                        onFocus={() => { setNameFocus(true) }}
                        onBlur={() => { setNameFocus(false) }}
                    />
                    <p id='form-note'
                        className={nameFocus && name && !validName ? 'instructions' : 'offscreen'}
                    >
                        <FontAwesomeIcon icon={faInfoCircle} />
                        2 to 100 characters <br />
                        Letters and spaces allowed. <br />
                        Be careful to not put space character last.
                    </p>

                    {/* Email */}

                    <label>E-mail
                        <span className={validEmail ? 'valid' : 'hide'}>
                            <FontAwesomeIcon icon={faCheck} />
                        </span>
                        <span className={validEmail || !email ? 'hide' : 'invalid'}>
                            <FontAwesomeIcon icon={faTimes} />
                        </span>
                    </label>
                    <input
                        type='text'
                        id='email'
                        name='email'
                        autoComplete='off'
                        onChange={(e) => { setEmail(e.target.value) }}
                        required
                        aria-invalid={validEmail ? 'false' : 'true'}
                        aria-describedby='form-note'
                        onFocus={() => { setEmailFocus(true) }}
                        onBlur={() => { setEmailFocus(false) }}
                    />
                    <p id='form-note'
                        className={emailFocus && email && !validEmail ? 'instructions' : 'offscreen'}
                    >
                        <FontAwesomeIcon icon={faInfoCircle} />
                        Must be valid e-mail address.
                    </p>


                    {/* Message */}

                    <label htmlFor='text'>
                        Your Message
                        <span className={validText ? 'valid' : 'hide'}>
                            <FontAwesomeIcon icon={faCheck} />
                        </span>
                        <span className={validText || !text ? 'hide' : 'invalid'}>
                            <FontAwesomeIcon icon={faTimes} />
                        </span>
                    </label>
                    <textarea
                        type='text'
                        id='text'
                        name='text'
                        autoComplete='off'
                        onChange={(e) => { setText(e.target.value) }}
                        required
                        aria-invalid={validText ? 'false' : 'true'}
                        aria-describedby='form-note'
                        onFocus={() => { setTextFocus(true) }}
                        onBlur={() => { setTextFocus(false) }}
                    />
                    <p id='form-note'
                        className={textFocus && text && !validText ? 'instructions' : 'offscreen'}
                    >
                        <FontAwesomeIcon icon={faInfoCircle} />
                        2 to 1000 characters.
                    </p>
                    <p className={errMsg ? 'errmsg' : 'offscreen'}
                        aria-live='assertive'
                    >{errMsg}</p >
                    <div>
                        <button type='submit' className="btn-primary" >Send email</button>
                        <a
                            href="https://calendly.com/weblience"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <button className="btn-secondary clickable">Book a meeting</button>
                        </a>
                    </div>
                </form >
            )
            }
        </div>
    )
}

export default ContactModal
