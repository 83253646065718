import React from "react";
import "./Portfolio.css";
import animationGif from "../assets/imgs/Animation.gif";
// import vona from "../assets/imgs/vona.png";
// import barber from "../assets/imgs/barber.png";
// import dom from "../assets/imgs/dom.png";
// import pizza from "../assets/imgs/pizza.png";
// import auto from "../assets/imgs/auto.png";
// import topanka from "../assets/imgs/topanka.png";
import AutoplayCarousel from "./AutoplayCarousel";

const Portfolio = () => {
  return (
    <div className="portfolio-container">
      <h2>Our Beautiful Work</h2>
      <p>
        From concept to fully optimized website that helps our clients thrive!
      </p>
      <img src={animationGif} alt="loading..." className="animation-gif" />

      <AutoplayCarousel />
    </div>
  );
};

export default Portfolio;
